<!--
   /**
      * newsList.vue
      * @module {src/modules/public}
      * @desc 前端新闻列表
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width:100%">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 0px 2.5%">
            <el-row :gutter="10">
              <housing />
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import housing from '../Housing'
export default {
  name: 'Login',
  components: {housing},
  data () {
    return {
      pageSize: 10, // 每页的数据条数
      skip: 0, // 起始
      limit: 1,
      id: 0,
      itemList: {},
      dataList: [
        {
          id:1,
          sale_code: "S567856723435",
          sale_name: "68元/2节体验课",
          org_code: "O567856723435",
          org_name: "童奥创意艺术馆",
          img: "/static/images/sale01.jpg",
          amount: "￥68.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:2,
          sale_code: "S567856723436",
          sale_name: "99元4节课程体验券",
          org_code: "O567856723436",
          org_name: "狮王教育",
          img: "/static/images/sale02.jpg",
          amount: "￥99.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:3,
          sale_code: "S567856723437",
          sale_name: "38.8元课程体验券",
          org_code: "O567856723437",
          org_name: "灰姑娘英国皇家芭蕾舞学院",
          img: "/static/images/sale03.jpg",
          amount: "￥38.80",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:4,
          sale_code: "S567856723438",
          sale_name: "99元/2节体验课程包",
          org_code: "O567856723438",
          org_name: "浅草堂书画院",
          img: "/static/images/sale04.jpg",
          amount: "￥99.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:5,
          sale_code: "S567856723439",
          sale_name: "9.9元课程体验券",
          org_code: "O567856723439",
          org_name: "清风棋院",
          img: "/static/images/sale05.jpg",
          amount: "￥9.90",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:6,
          sale_code: "S567856723440",
          sale_name: "68元/2节私教体验课",
          org_code: "O567856723440",
          org_name: "迈邸健身会所",
          img: "/static/images/sale06.jpg",
          amount: "￥68.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:7,
          sale_code: "S567856723441",
          sale_name: "100元百果园优惠券",
          org_code: "O567856723441",
          org_name: "恒福物业",
          img: "/static/images/sale07.jpg",
          amount: "￥100.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:8,
          sale_code: "S567856723442",
          sale_name: "星礼卡100元",
          org_code: "O567856723442",
          org_name: "星巴克 (中国)",
          img: "/static/images/sale08.png",
          amount: "￥100.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:9,
          sale_code: "S567856723443",
          sale_name: "健康工房 $10 現金劵",
          org_code: "O567856723443",
          org_name: "健康工房",
          img: "/static/images/sale09.png",
          amount: "$10.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:10,
          sale_code: "S567856723444",
          sale_name: "大快活$20電子現金券",
          org_code: "O567856723444",
          org_name: "大快活",
          img: "/static/images/sale10.png",
          amount: "$20.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:11,
          sale_code: "S567856723445",
          sale_name: "Green Common $50 現金劵",
          org_code: "O567856723445",
          org_name: "Green Monday",
          img: "/static/images/sale11.png",
          amount: "$50.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
        {
          id:12,
          sale_code: "S567856723446",
          sale_name: "Giordano $50現金劵",
          org_code: "O567856723446",
          org_name: "Giordano Limited",
          img: "/static/images/sale12.png",
          amount: "$50.00",
          status: "1",
          create_time: "2021-01-21 10:45"
        },
      ],

      pageIndex: 1,
      totalPage: 10,
      dataListLoading: false
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
    },
    goToNews (sale_code) {
      this.$router.push(
        {
          path: '/serviceDetails',
          query: {
            sale_code: sale_code
          }
        }
      )
    },
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
.saleName{
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
  color: #666;
}
.orgName{
  font-size: 14px;
  text-align: right;
  color: #999;
}
.saleAmount{
  font-size: 28px;
  color: #f90;
  text-align: center;
  padding-bottom: 10px;
}
</style>
